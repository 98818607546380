import React, { useState, useCallback } from 'react';
import { Form, Input, Upload, Button, Table, Space, message, Progress, Card, Radio, Input as AntInput, Tooltip, Row, Col } from 'antd';
import { UploadOutlined, EditOutlined, EyeOutlined, InfoCircleOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import api from '../../../api/api';
import LogoUpload from './components/LogoUpload';
import { useSelector } from 'react-redux';

const { TextArea } = AntInput;

interface FileWithPreview extends File {
  preview?: string;
  s3Url?: string;
}

interface FormState {
  productName: string;
  insurerLogo: FileWithPreview | null;
  partnerLogo: FileWithPreview | null;
  emailHeaderImage: FileWithPreview | null;
  emailHtml: string;
  handguide: string | null;
  handguideType: 'Residential' | 'Commercial';
  excelData: any[];
  selectedRow: any | null;
  excelHeaders: string[];
  excelFile: File | null;
  emailSubject: string;
}

interface SavedEmailData {
  headerImage: string | null;
  htmlContent: string;
}

interface UploadState {
  handguideUploaded: boolean;
  excelUploaded: boolean;
}

const STORAGE_KEY = 'ecard_saved_template';

const EcardSendTool: React.FC = () => {
  const [form] = Form.useForm();
  const [formState, setFormState] = useState<FormState>({
    productName: '',
    insurerLogo: null,
    partnerLogo: null,
    emailHeaderImage: null,
    emailHtml: '',
    handguide: null,
    handguideType: 'Residential',
    excelData: [],
    selectedRow: null,
    excelHeaders: [],
    excelFile: null,
    emailSubject: ''
  });
  const [sending, setSending] = useState(false);
  const [progress, setProgress] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [uploadState, setUploadState] = useState<UploadState>({
    handguideUploaded: false,
    excelUploaded: false
  });
  const brokerName =  useSelector((state: any) => state.brokerconfig.brandName);

  const saveToLocalStorage = useCallback((state: Partial<FormState>) => {
    if (state.emailHtml || state.emailHeaderImage) {
      const toSave = {
        htmlContent: state.emailHtml ?? formState.emailHtml,
        headerImage: state.emailHeaderImage?.s3Url ?? formState.emailHeaderImage?.s3Url ?? null,
      };
      localStorage.setItem(STORAGE_KEY, JSON.stringify(toSave));
    }
  }, [formState.emailHtml, formState.emailHeaderImage]);

  const uploadToS3 = async (file: File, folderName: string = formState.productName || 'ecard') => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('folder_name', folderName);
      const response = await api.utils.uploadToS3(formData);
      return response?.result;
    } catch (error) {
      message.error('Failed to upload file to S3');
      console.error('S3 upload error:', error);
      return null;
    }
  };

  const handleFileUpload = async (file: File, type: keyof FormState) => {
    if (type === 'insurerLogo' || type === 'partnerLogo') {
      const reader = new FileReader();
      reader.onload = () => {
        const fileWithPreview = file as FileWithPreview;
        fileWithPreview.preview = reader.result as string;
        setFormState(prev => ({ ...prev, [type]: fileWithPreview }));
      };
      reader.readAsDataURL(file);
    } else if (type === 'emailHeaderImage') {
      const reader = new FileReader();
      reader.onload = async () => {
        const fileWithPreview = file as FileWithPreview;
        fileWithPreview.preview = reader.result as string;
        
        const s3Url = await uploadToS3(file, `${formState.productName}/header_image`);
        if (s3Url) {
          fileWithPreview.s3Url = s3Url;
          setFormState(prev => ({ ...prev, emailHeaderImage: fileWithPreview }));
          saveToLocalStorage({ emailHeaderImage: fileWithPreview });
        }
      };
      reader.readAsDataURL(file);
    } else if (type === 'handguide') {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result as string;
        setFormState(prev => ({ ...prev, handguide: base64String }));
        setUploadState(prev => ({ ...prev, handguideUploaded: true }));
      };
      reader.readAsDataURL(file);
    }
    return false;
  };

  const handleExcelUpload = async (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const fileData = e.target?.result;
      if (!fileData) return;
      
      const workbook = XLSX.read(fileData, { type: 'binary' });
      const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
      const headers = XLSX.utils.sheet_to_json(firstSheet, { header: 1 })[0] as string[];
      
      let jsonData : any;
      if (workbook.SheetNames.length > 1) {
        const secondSheet = workbook.Sheets[workbook.SheetNames[1]];
        jsonData = XLSX.utils.sheet_to_json(secondSheet) as any[];
      } else {
        jsonData = XLSX.utils.sheet_to_json(firstSheet) as any[];
      }
      
      setFormState(prev => ({ 
        ...prev, 
        excelHeaders: headers,
        excelData: jsonData,
        selectedRow: jsonData[0] || null,
        excelFile: file
      }));
      setUploadState(prev => ({ ...prev, excelUploaded: true }));
    };
    reader.readAsBinaryString(file);
    return false;
  };

  const handleHtmlUpload = async (file: File) => {
    const text = await file.text();
    setFormState(prev => ({ ...prev, emailHtml: text }));
    saveToLocalStorage({ emailHtml: text });
    return false;
  };

  const mergePlaceholders = (template: string, data: any) => {
    let result = template;
    Object.entries(data).forEach(([key, value]) => {
      result = result.replace(new RegExp(`{{${key}}}`, 'g'), value?.toString() || '');
    });
    return result;
  };

  if (brokerName?.toLowerCase() !== "bharatsure") {
    return (
      <div style={{ padding: '24px' }}>
        <h1>Unauthorized access</h1>
      </div>
    )
  }

  const getEmailWithHeader = (content: string) => {
    const headerImg = formState.emailHeaderImage?.s3Url || formState.emailHeaderImage?.preview;
    if (!headerImg) return content;
    
    return `
    <!DOCTYPE html>
      <html>
        <head>
          <meta charset="utf-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
        </head>
        <body style="margin: 0; padding: 0;">
          <img src="${headerImg}" style="width: 100%; max-width: 100%; display: block;" class="header-img" alt="Email Header" />
          <div class="content" style="padding: 20px;">
            ${content}
          </div>
        </body>
      </html>
    `;
  };

  const removeBase64Mimetype = (base64String: string) => {
    return base64String?.split(',')[1] || '';
  };

  const getPayload = async () => {
    const formData = new FormData();
    formData.append('product_name', formState.productName);
    formData.append('file', formState.excelFile || '');
    formData.append('handguide', removeBase64Mimetype(formState.handguide || ''));
    formData.append('insurer_logo', formState.insurerLogo?.preview || '');
    formData.append('partner_logo', formState.partnerLogo?.preview || '');
    formData.append('email_subject', formState.emailSubject || '');

    let htmlContent = formState.emailHtml;
    if (formState.emailHeaderImage?.s3Url) {
      htmlContent = htmlContent.replace(formState.emailHeaderImage.preview || '', formState.emailHeaderImage.s3Url);
    }
    formData.append('html_content', getEmailWithHeader(htmlContent));

    return formData;
  }

  const handleSendEmails = async () => {
    setSending(true);
    setProgress(0);

    try {
      const formData = await getPayload();
      const response = await api.utils.sendEcardEmails(formData);
      message.success(response.message || 'All emails sent successfully!');
    } catch (error) {
      message.error('Failed to send emails');
      console.error('Bulk email error:', error);
    } finally {
      setSending(false);
      setProgress(0);
    }
  };

  const getColumns = () => {
    if (formState.excelData.length === 0) return [];
    const firstRow = formState.excelData[0];
    return Object.keys(firstRow).map(key => ({
      title: key,
      dataIndex: key,
      key: key
    }));
  };

  const getRequiredFieldsMessage = () => {
    if (!formState.productName) return 'Please enter product name';
    if (!formState.emailSubject) return 'Please enter email subject';
    if (!formState.emailHtml) return 'Please upload or use saved email template';
    if (!formState.emailHeaderImage) return 'Please upload or use saved header image';
    if (!formState.insurerLogo) return 'Please upload insurer logo';
    if (!formState.partnerLogo) return 'Please upload partner logo';
    if (!uploadState.handguideUploaded) return 'Please upload handguide';
    if (!uploadState.excelUploaded) return 'Please upload Excel data';
    return '';
  };

  return (
    <div style={{ padding: '24px' }}>
      <Card title="E-Card Sending Tool">
        <Form form={form} layout="vertical" requiredMark={true}>
          <Form.Item label="Product Name" name="productName" required>
            <Input 
              onChange={e => {
                const value = e.target.value;
                setFormState(prev => ({ ...prev, productName: value }));
              }}
            />
          </Form.Item>

          <Form.Item label="Email Subject" name="emailSubject" required>
            <Input 
              onChange={e => {
                const value = e.target.value;
                setFormState(prev => ({ ...prev, emailSubject: value }));
              }}
            />
          </Form.Item>

          <Card title="Email Content" style={{ marginBottom: 16 }}>
            <Form.Item 
              label={
                <Space>
                  Email Header Image
                  <Tooltip title="Recommended size: 2048px x 512px (4:1)">
                    <InfoCircleOutlined />
                  </Tooltip>
                </Space>
              } 
              required
            >
              <Space direction="vertical" style={{ width: '100%' }}>
                <Space wrap>
                  <Upload 
                    beforeUpload={file => handleFileUpload(file, 'emailHeaderImage')}
                    accept="image/*"
                    showUploadList={false}
                  >
                    <Button icon={<UploadOutlined />}>Upload Email Header Image</Button>
                  </Upload>
                  {localStorage.getItem(STORAGE_KEY) && 
                    JSON.parse(localStorage.getItem(STORAGE_KEY) || '{}').headerImage && (
                    <Button 
                      onClick={() => {
                        const savedState = JSON.parse(localStorage.getItem(STORAGE_KEY) || '{}');
                        setFormState(prev => ({
                          ...prev,
                          emailHeaderImage: savedState.headerImage ? {
                            preview: savedState.headerImage
                          } as FileWithPreview : null,
                        }));
                      }}
                    >
                      Use Saved Header Image
                    </Button>
                  )}
                </Space>
                {formState.emailHeaderImage?.preview && (
                  <div>
                    <img 
                      src={formState.emailHeaderImage.preview} 
                      alt="Email Header Preview" 
                      style={{ maxWidth: '180px', height: 'auto', marginTop: 8 }} 
                    />
                  </div>
                )}
              </Space>
            </Form.Item>

            <Form.Item label="Email HTML Template" required>
              <Space direction="vertical" style={{ width: '100%' }}>
                <Space wrap>
                  <Upload 
                    beforeUpload={handleHtmlUpload}
                    accept=".html"
                    showUploadList={false}
                  >
                    <Button icon={<UploadOutlined />}>Upload HTML Template</Button>
                  </Upload>
                  {localStorage.getItem(STORAGE_KEY) && 
                    JSON.parse(localStorage.getItem(STORAGE_KEY) || '{}').htmlContent && (
                    <Button 
                      onClick={() => {
                        const savedState = JSON.parse(localStorage.getItem(STORAGE_KEY) || '{}');
                        setFormState(prev => ({
                          ...prev,
                          emailHtml: savedState.htmlContent || '',
                        }));
                      }}
                    >
                      Use Saved Template
                    </Button>
                  )}
                  {formState.emailHtml && (
                    <Button 
                      icon={editMode ? <EyeOutlined /> : <EditOutlined />}
                      onClick={() => setEditMode(!editMode)}
                    >
                      {editMode ? 'Show Preview' : 'Edit HTML'}
                    </Button>
                  )}
                </Space>
                {editMode && formState.emailHtml && (
                  <TextArea
                    value={formState.emailHtml}
                    onChange={(e) => {
                      const value = e.target.value;
                      setFormState(prev => ({ ...prev, emailHtml: value }));
                      saveToLocalStorage({ emailHtml: value });
                    }}
                    style={{ 
                      fontFamily: 'monospace',
                      minHeight: '300px',
                      marginTop: 16
                    }}
                  />
                )}
              </Space>
            </Form.Item>

            {formState.emailHtml && !editMode && (
              <div style={{ marginTop: 16 }}>
                <h3>Email Preview</h3>
                <Card bodyStyle={{ padding: 0 }}>
                  <iframe
                    srcDoc={getEmailWithHeader(formState.selectedRow 
                      ? mergePlaceholders(formState.emailHtml, formState.selectedRow)
                      : formState.emailHtml)}
                    style={{
                      width: '100%',
                      height: '500px',
                      border: 'none',
                      backgroundColor: 'white'
                    }}
                    title="Email Preview"
                  />
                </Card>
              </div>
            )}
          </Card>

          <div style={{ marginBottom: 16 }}>
            <Row gutter={24}>
              <Col span={12}>
                <LogoUpload 
                  label="Insurer Logo"
                  onUpload={file => handleFileUpload(file, 'insurerLogo')}
                  preview={formState.insurerLogo?.preview}
                />
              </Col>

              <Col span={12}>
                <LogoUpload 
                  label="Partner Logo"
                  note="Upload partner logo with broker logo. Full image for E-card footer is recommended. Recommended size: 2048px x 512px (4:1)"
                  onUpload={file => handleFileUpload(file, 'partnerLogo')}
                  preview={formState.partnerLogo?.preview}
                />
              </Col>
            </Row>
          </div>

          <Form.Item label="Handguide" required>
            <Space direction="vertical">
              <Radio.Group 
                value={formState.handguideType}
                onChange={e => setFormState(prev => ({ ...prev, handguideType: e.target.value }))}
              >
                <Radio.Button value="Residential">Residential</Radio.Button>
                <Radio.Button value="Commercial">Commercial</Radio.Button>
              </Radio.Group>
              <Upload 
                beforeUpload={file => handleFileUpload(file, 'handguide')}
                accept=".pdf"
              >
                <Button icon={<UploadOutlined />}>
                  Upload {formState.handguideType} Guide
                </Button>
              </Upload>
              {formState.handguide && (
                <div style={{ color: '#52c41a' }}>✓ {formState.handguideType} guide uploaded</div>
              )}
            </Space>
          </Form.Item>

          <Form.Item 
            label={
              <Space>
                Excel Data
                <Tooltip title="Upload Excel file with headers in first sheet. Customer data will be taken from second sheet.">
                  <InfoCircleOutlined />
                </Tooltip>
              </Space>
            }
            required
          >
            <Upload 
              beforeUpload={handleExcelUpload}
              accept=".xlsx,.xls"
            >
              <Button icon={<UploadOutlined />}>Upload Excel Data</Button>
            </Upload>
          </Form.Item>

          {formState.excelHeaders.length > 0 && (
            <Card size="small" title="Available Headers" style={{ marginTop: 8 }}>
              <ul style={{ margin: 0, paddingLeft: 20 }}>
                {formState.excelHeaders.map((header, index) => (
                  <li key={index}>{header}</li>
                ))}
              </ul>
            </Card>
          )}

          {formState.excelData.length > 0 && (
            <div style={{ marginBottom: 16 }}>
              <h3>Customer Data Preview</h3>
              <Table 
                dataSource={formState.excelData}
                columns={getColumns()}
                pagination={{ pageSize: 5 }}
                rowKey={(record) => record.SR_NO || Math.random().toString()}
                onRow={(record) => ({
                  onClick: () => setFormState(prev => ({ ...prev, selectedRow: record }))
                })}
              />
            </div>
          )}

          <Space size="large">
            <Tooltip title={getRequiredFieldsMessage()}>
              <Button 
                type="primary" 
                onClick={handleSendEmails}
                disabled={!!getRequiredFieldsMessage()}
              >
                Send All Emails
              </Button>
            </Tooltip>
          </Space>

          {sending && (
            <Progress percent={progress} style={{ marginTop: 16 }} />
          )}
        </Form>
      </Card>
    </div>
  );
};

export default EcardSendTool;