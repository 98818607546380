import React from 'react';
import { Form, Upload, Button, Space, Tooltip } from 'antd';
import { InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';

interface LogoUploadProps {
  label: string;
  onUpload: (file: File) => Promise<boolean>;
  preview?: string;
  note?: string;
}

const LogoUpload: React.FC<LogoUploadProps> = ({ label, onUpload, preview, note }) => {
  return (
    <Form.Item
      label={
        <Space>
          {label}
          {note && (
            <Tooltip title={note}>
              <InfoCircleOutlined />
            </Tooltip>
          )}
        </Space>
      }
      required
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <Upload beforeUpload={(file) => onUpload(file)} accept="image/*" showUploadList={false}>
          <Button icon={<UploadOutlined />}>Upload {label}</Button>
        </Upload>
        {preview && (
          <div>
            <img src={preview} alt={`${label} Preview`} style={{ maxWidth: 200, marginTop: 8 }} />
          </div>
        )}
      </Space>
    </Form.Item>
  );
};

export default LogoUpload;
