import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import Loader from "../../../components/Loader/Loader";
import ClientBanner, { Banner, Client } from "./ClientBanner";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import api from "../../../api/api";
import { errorHandler } from "../../../utils/helper";
import { successNotification } from "../../../components/UI/Toast/Toast";
import { Card, Col, Modal, Row } from "antd";


export const ClientBannerList: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false),
    [listOfClients, setListOfClients] = useState<Client[]>([]),
    [banners, setBanners] = useState<Banner[]>([]),
    [selectedBanner, setSelectedBanner] = useState<Banner | null>(null),
    [open, setOpen] = useState<boolean>(false),
    [isViewOnly, setIsViewOnly] = useState<boolean>(false),
    showAddBannerCard = banners.length < 6,
    onClose = useCallback(() => {
      setSelectedBanner(null)
      setOpen(false)
      setIsViewOnly(false)
    }, []),
    fetchBanners = useCallback(() => {
      setLoading(true)
      api.settings.getBrokerBanner()
        .then((res) => {
          setBanners(res?.data?.results)
        })
        .catch((err) => {
          errorHandler(err?.response?.data)
        })
        .finally(() => setLoading(false))
    }, []),
    deleteBanner = useCallback((uuid: string) => {
      setLoading(true)
      api.settings.deleteBrokerBanner(uuid)
        .then((res) => {
          setBanners((prev) => prev.filter((item) => item.uuid !== uuid))
          successNotification('Banner deleted successfully')
        })
        .catch((err) => {
          errorHandler(err?.response?.data)
        })
        .finally(() => setLoading(false))
    }, []),
    updateBanner = useCallback((uuid: string, data: Banner) => {
      setLoading(true)
      const payload = {
        image: data?.image,
        mobile_image: data?.mobile_image,
        redirect_url: data?.redirect_url,
        title: data?.title,
        is_all: data?.is_all,
        organisations_uuid: (data?.organisations && !data.is_all) ? data?.organisations : [],
      }
      api.settings.updateBrokerBanner(uuid, payload)
        .then((res) => {
          setBanners((prev) => prev.map((item) => item.uuid === uuid ? res?.data : item))
          onClose();
          successNotification('Banner updated successfully')
        })
        .catch((err) => {
          errorHandler(err?.response?.data)
        })
        .finally(() => setLoading(false))
    }, [onClose]),
    addBanner = useCallback((data: Banner) => {
      setLoading(true)
      const payload = {
        image: data?.image,
        mobile_image: data?.mobile_image,
        redirect_url: data?.redirect_url,
        title: data?.title,
        is_all: data?.is_all,
        organisations_uuid: (data?.organisations && !data.is_all) ? data?.organisations : [],
      }
      api.settings.createBrokerBanner(payload)
        .then((res) => {
          setBanners((prev: Banner[]) => [...prev, res?.data])
          onClose();
          successNotification('Banner added successfully')
        })
        .catch((err) => {
          errorHandler(err?.response?.data)
        })
        .finally(() => setLoading(false))
    }, [onClose]),
    addNewBanner = useCallback(() => {
      setSelectedBanner({ uuid: '', title: '', image: '', mobile_image: '', is_all: true, new: true })
      setIsViewOnly(false)
      setOpen(true)
    }, []),
    fetchClients = useCallback(() => {
      api.claims_management
        .populateClaims({})
        .then((res) => {
          setListOfClients(res?.data?.organisations_list);
        })
        .catch((error) => {
          errorHandler(error?.response?.data);
        });
    }, []),
    onEdit = useCallback((banner: Banner) => {
      setSelectedBanner(banner)
      setIsViewOnly(false)
      setOpen(true)
    }, []),
    onDelete = useCallback((banner: Banner) => {
      Modal.confirm({
        title: 'Are you sure you want to delete this banner?',
        onOk: () => deleteBanner(banner.uuid),
      });
    }, [deleteBanner]),
    onView = useCallback((banner: Banner) => {
      setSelectedBanner(banner)
      setIsViewOnly(true)
      setOpen(true)
    }, [])

  useEffect(() => {
    fetchBanners();
  }, [fetchBanners])

  useEffect(() => {
    fetchClients();
  }, [fetchClients])

  return <Loader loading={loading}>
    <div className="mt-8">
      Customize the member dashboard/app with up to
      <strong>&nbsp;6 marketing banners. </strong>
      These will appear prominently at the top of the screen. The sequence of the banners will be the same as here.
    </div>
    <Row gutter={[24, 24]} className="mt-48">
      {banners?.map((banner) => (
        <Col key={banner.uuid} span={8}>
          <Card
            onClick={() => onView(banner)}
            hoverable
            cover={
              <img
                alt={banner.title}
                src={banner.mobile_image || banner.image}
                style={{ objectFit: 'cover', height: 200, width: '100%' }}
              />
            }
            actions={[
              <EditOutlined key="edit" onClick={(e) => {
                e.stopPropagation()
                onEdit(banner)
              }} />,
              <DeleteOutlined key="delete" onClick={(e) => {
                e.stopPropagation()
                onDelete(banner)
              }} />
            ]}
          >
            <Card.Meta title={banner.title} />
          </Card>
        </Col>
      ))}

      {showAddBannerCard && (
        <Col span={8}>
          <Card
            hoverable
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 272, // Similar height as other cards (200px image + card content)
              cursor: 'pointer'
            }}
            onClick={addNewBanner}
          >
            <div style={{ textAlign: 'center' }}>
              <PlusOutlined style={{ fontSize: 48, marginBottom: 8 }} />
              <div>Add Banner</div>
            </div>
          </Card>
        </Col>
      )}
    </Row>

    {open && selectedBanner && <ClientBanner
      open={open}
      onClose={onClose}
      key={selectedBanner?.uuid}
      banner={selectedBanner}
      updateBanner={updateBanner}
      addBanner={addBanner}
      clients={listOfClients}
      isViewOnly={isViewOnly}
    />}
  </Loader>
};