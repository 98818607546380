import { Badge, Col, Dropdown, Image, Modal, Row, Space } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { useHotkeys } from 'react-hotkeys-hook';
import NotificationDrawer from '../NotificationDrawer/NotificationDrawer';
import './_fixedheader.scss';
import { Bell, HelpCircle, LogOut, NavArrowDown, Settings, UserCircle } from 'iconoir-react';
import api from '../../api/api';
import { authActions } from '../../store/auth';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { errorHandler } from '../../utils/helper';
const { confirm } = Modal;

const TextWithIcon = (icon: React.ReactNode, text: string, onClick: VoidFunction) => (
  <div
    className="item"
    onClick={onClick}
    style={{
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      gap: '8px',
      justifyContent: 'flex-start',
      alignItems: 'normal',
    }}
  >
    {icon}
    {text}
    {/* <Row className='item' style={{ width: '100%', margin: '0' }} align='middle' gutter={8} onClick={onClick}>
  <Col>
    {icon}
  </Col>
  <Col>
    {text}
  </Col>
</Row> */}
  </div>
);

const FixedHeader: React.FC = () => {
  const dispatch = useDispatch(),
    name = useSelector((state: any) => state.auth.name),
    // searchRef = useRef(false as any),
    [notificationDrawerVisible, setNotificationDrawerVisible] = useState<boolean>(false),
    // [searchTerm, setSearchTerm] = useState<string | undefined>(undefined),
    navigate = useNavigate(),
    notification_count = useSelector((state: any) => state.auth.notification_count),
    [dropdownOpen, setDropdownOpen] = useState(false),
    navigateToSettings = () => {
      navigate('/configuration');
      setDropdownOpen(false);
    },
    navigateToKnowledgeCenter = () => {
      setDropdownOpen(false);
      window.open(
        'https://learn.zohopublic.in/external/manual/partner/article/portal-handguide-final?p=ceeef6965bc937132fb23f08ade6a69639bcc7203aeba9fa5054c1f9cf669a91',
        '_blank'
      );
      setDropdownOpen(false);
    },
    logoutHandler = () => {
      api.profile
        .logout()
        .then((res) => {
          dispatch(authActions.logout());
        })
        .catch((err) => {
          errorHandler(err?.data?.message ?? 'Something went wrong');
        });
    },
    logoutConfirmation = () => {
      setDropdownOpen(false);
      confirm({
        title: 'Do you sure you want to logout?',
        icon: <ExclamationCircleFilled />,
        // content: 'Some descriptions',
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
          logoutHandler();
        },
        onCancel() {},
      });
    },
    groupOverlay = (
      <div className="overflow-menu">
        {TextWithIcon(<Settings />, 'Configuration', navigateToSettings)}
        {TextWithIcon(<HelpCircle />, 'Help Center', navigateToKnowledgeCenter)}
        {TextWithIcon(<LogOut />, 'Logout', logoutConfirmation)}
      </div>
    );

  // useHotkeys("shift+f", () => {
  //   setTimeout(() => {
  //     searchRef.current.focus();
  //   }, 20);
  // });

  useHotkeys('shift+n', () => {
    setTimeout(() => {
      setNotificationDrawerVisible(!notificationDrawerVisible);
    }, 20);
  });

  useHotkeys('shift+s', () => {
    setTimeout(() => {
      navigate('/settings');
    }, 20);
  });

  return (
    <div className="fheader">
      {notificationDrawerVisible && (
        <NotificationDrawer visible={notificationDrawerVisible} onClose={() => setNotificationDrawerVisible(false)} />
      )}
      <Row justify="space-between">
        <Col>
          {/* <Input
            allowClear
            autoFocus
            value={searchTerm}
            ref={searchRef}
            className="search-input universal"
            bordered={true}
            prefix={<img src={searchIcon} alt="search" />}
            placeholder={"Search (⌛Feature in building)"}
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
            disabled
            hidden
          /> */}
        </Col>
        {/* <Col>
          <Row justify="end" gutter={24} align='middle'>
            <Col
              onClick={() => {
                setNotificationDrawerVisible(true);
              }}
            >
              <Badge count={notification_count}>
                <Bell color='#93959B' />
              </Badge>
            </Col>
            <Col>
              <Row align='middle' gutter={8}>
                <Col>
                  <Image src={'../../assets/icons/user-profile.png'} />
                </Col>
                <Col>
                  <Dropdown className='pointer-on-hover' overlay={groupOverlay} trigger={['click']}>
                    <Space>
                      <Row className='profile' align='middle' gutter={8}>
                        <Col>
                          {name}
                        </Col>
                        <Col>
                          <NavArrowDown />
                        </Col>
                      </Row>
                    </Space>
                  </Dropdown>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col> */}
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            gap: '24px',
            justifyContent: 'flex-end',
            alignItems: 'normal',
            color: '#93959B',
          }}
        >
          <Badge count={notification_count}>
            <Bell
              // color="#93959B"
              className="pointer-on-hover"
              onClick={() => {
                setNotificationDrawerVisible(true);
              }}
            />
          </Badge>
          <Dropdown overlay={groupOverlay} trigger={['click', 'hover']}>
            <div
              className="profile"
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '8px',
                cursor: 'pointer',
                // color: '#93959B'
              }}
            >
              <UserCircle />
              <div>{name}</div>
              <div>
                <NavArrowDown />
              </div>
            </div>
          </Dropdown>
        </div>
      </Row>
    </div>
  );
};

export default FixedHeader;
