import { Form, FormInstance, Image } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import ImgCrop from 'antd-img-crop';
import { UploadProps } from 'antd/lib/upload';
import { toTitleCase } from '../../../utils/helper';

interface BannerUploaderProps {
  aspect: number;
  isMobile?: boolean;
  uploadProps: UploadProps;
  onModalCancel: () => void;
  onModalOk: () => void;
  beforeCrop: (file: File) => boolean;
  form: FormInstance;
}

export const BannerUploader = ({
  aspect,
  isMobile = false,
  uploadProps,
  onModalCancel,
  onModalOk,
  beforeCrop,
  form
}: BannerUploaderProps) => {
  const deviceType = isMobile ? 'mobile' : 'desktop';
  const recommendedSize = isMobile ? '1024×512px (2:1)' : '1920×320px (6:1)';
  const fieldName = isMobile ? 'mobile_image' : 'image';
  const DEFAULT_PREVIEW_IMAGE = 'https://placehold.co/1920x320/36454F/FFF?text=Banner+Preview+(1920x320)';
const DEFAULT_MOBILE_PREVIEW_IMAGE = 'https://placehold.co/320x160/36454F/FFF?text=Mobile+Banner+Preview+(1024x512)';

  return (
    <Form.Item
      name={fieldName}
      valuePropName="image"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      validateTrigger={['onChange', 'onBlur']}
      rules={[{
        required: true,
        message: "Please upload a banner image",
        validator: (_, value) => {
          const fileList = uploadProps.fileList || [];
          if (value || fileList.length > 0) {
            return Promise.resolve();
          }
          return Promise.reject(new Error('Please upload a banner image'));
        }
      }]}
      label={<>
        {isMobile ? 'Mobile & Tablet Banner Image' : 'Desktop Banner Image'}
      </>}
      tooltip={{
        title: <span style={{ fontSize: '12px', fontStyle: 'italic' }}>
          Recommended size: {recommendedSize}
          <br />
          Accepted formats: PNG, SVG, JPG, JPEG, WEBP
        </span>
      }}
    >
      <ImgCrop
        aspect={aspect}
        quality={1}
        modalTitle={`Crop ${toTitleCase(deviceType)} Banner`}
        modalWidth={800}
        showGrid
        showReset
        onModalCancel={() => {
          onModalCancel();
          form.setFieldValue(fieldName, undefined);
        }}
        beforeCrop={beforeCrop}
        onModalOk={onModalOk}
      >
        <Dragger
          {...uploadProps}
          // listType="picture"
          showUploadList={false}
          onChange={(info) => {
            uploadProps.onChange?.(info);
            if (info.file.status === 'done' && info.file.response?.url) {
              form.setFieldValue(fieldName, info.file.response.url);
            }
          }}
        >
          {isMobile && form.getFieldValue('mobile_image') && <p className="ant-upload-drag-icon" style={{ marginTop: "24px" }}>
            <Image src={form.getFieldValue('mobile_image') ?? DEFAULT_MOBILE_PREVIEW_IMAGE} preview={false} style={{
              objectFit: 'contain',
              height: '150px',
            }} />
          </p>}
          {!isMobile && form.getFieldValue('image') && <p className="ant-upload-drag-icon" style={{ marginTop: "24px" }}>
            <Image src={form.getFieldValue('image') ?? DEFAULT_PREVIEW_IMAGE} preview={false} style={{
              objectFit: 'contain',
              height: '150px',
            }} />
          </p>}
          <p className="ant-upload-text" style={{ margin: "24px 0" }}>
            Drag and drop your {deviceType} banner here
            <br /> or click here to upload
          </p>
        </Dragger>
      </ImgCrop>
    </Form.Item>
  );
};