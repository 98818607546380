import { Col, Form, Input, Modal, Radio, Row, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useCallback, useState } from 'react';
import Loader from '../../../components/Loader/Loader';
import { Button } from '../../../components/UI/Button/Button';
import { errorNotification } from '../../../components/UI/Toast/Toast';
import apiEndpoints from '../../../api/apiEndPoints';
import { errorHandler, getAccessToken } from '../../../utils/helper';
import Tooltip from '../../../components/UI/Tooltip/Tooltip';
import { ImagePreview } from '../../../components/UI/ImagePreview/ImagePreview';
import { BannerUploader } from '../../../components/UI/BannerUploader/BannerUploader';
import { ReadOnlyWrapper } from '../../../components/ReadOnlyWrapper/ReadOnlyWrapper';

export interface Banner {
  uuid: string;
  title: string;
  redirect_url?: string;
  image: string;
  mobile_image?: string;
  organisations?: Array<string>;
  is_all: boolean;
  new?: boolean;
}

export interface Client {
  uuid: string;
  brand_name: string;
}

interface Props {
  banner: Banner;
  updateBanner: (id: string, data: Banner) => void;
  addBanner: (data: Banner) => void;
  clients: Array<Client>;
  open: boolean;
  onClose: () => void;
  isViewOnly: boolean;
}

const ClientBanner = ({ banner, updateBanner, addBanner, clients, open, onClose, isViewOnly }: Props) => {
  const [form] = useForm();
  const [isAll, setIsAll] = useState<boolean>(banner?.is_all);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [desktopPreviewUrl, setDesktopPreviewUrl] = useState<string>(banner?.image || '');
  const [mobilePreviewUrl, setMobilePreviewUrl] = useState<string>(banner?.mobile_image || '');
  const [desktopFileList, setDesktopFileList] = useState<any[]>(
    banner?.image ? [{ url: banner.image, status: 'done' }] : []
  );
  const [mobileFileList, setMobileFileList] = useState<any[]>(
    banner?.mobile_image ? [{ url: banner.mobile_image, status: 'done' }] : []
  );
  const [desktopUploadReady, setDesktopUploadReady] = useState<boolean>(false);
  const [mobileUploadReady, setMobileUploadReady] = useState<boolean>(false);

  const validateFormat = (file: File): boolean => {
    const isValidFormat = /\.(png|svg|jpg|jpeg|webp)$/i.test(file.name);
    if (!isValidFormat) {
      errorNotification('Please upload only PNG, SVG, JPG, JPEG or WEBP files');
    }
    return isValidFormat;
  };

  const createUploadProps = (isMobile: boolean) => ({
    name: 'image',
    accept: '.png, .svg, .jpg, .jpeg, .webp',
    multiple: false,
    customRequest: ({ file, onSuccess, onError }: any) => {
      if (!(isMobile ? mobileUploadReady : desktopUploadReady)) {
        return;
      }

      const formData = new FormData();
      formData.append('image', file);

      fetch(apiEndpoints.imageUpload, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
        },
        body: formData,
      })
        .then(res => res.json())
        .then(response => {
          onSuccess(response);
        })
        .catch(err => {
          onError(err);
        });
    },
    maxCount: 1,
    fileList: isMobile ? mobileFileList : desktopFileList,
    beforeUpload: (file: any) => {
      return validateFormat(file);
    },
    onChange(info: any) {
      const { status, response } = info.file;
      if (!(isMobile ? mobileUploadReady : desktopUploadReady)) {
        isMobile ? setMobileFileList([]) : setDesktopFileList([]);
        return;
      }

      isMobile ? setMobileFileList(info.fileList.slice(-1)) : setDesktopFileList(info.fileList.slice(-1));

      if (status === 'done') {
        isMobile ? setMobilePreviewUrl(response.url) : setDesktopPreviewUrl(response.url);
      } else if (status === 'error') {
        errorNotification(info?.file?.response?.error || 'Error: Please recheck the image and try again');
        isMobile ? setMobilePreviewUrl(banner?.mobile_image || '') : setDesktopPreviewUrl(banner?.image || '');
        isMobile ? setMobileFileList([]) : setDesktopFileList([]);
      }
    },
    onRemove: () => {
      if (isMobile) {
        setMobilePreviewUrl('');
        setMobileFileList([]);
        setMobileUploadReady(false);
      } else {
        setDesktopPreviewUrl(banner?.image || '');
        setDesktopFileList([]);
        setDesktopUploadReady(false);
      }
      return true;
    }
  });

  const submitHandler = useCallback(() => {
    if (!desktopPreviewUrl) {
      errorNotification('Please upload a desktop banner image');
      return;
    }

    setBtnLoading(true);
    form
      .validateFields()
      .then((values) => {
        const data = {
          ...values,
          title: values?.title,
          image: values.image || desktopPreviewUrl,
          mobile_image: values.mobile_image || mobilePreviewUrl,
        };
        !banner?.new ? updateBanner(banner.uuid, { ...data, uuid: banner.uuid }) : addBanner(data);
      })
      .catch((errorInfo) => {
        if (errorInfo.errorFields?.length > 0) {
          const firstError = errorInfo.errorFields[0];
          errorNotification(firstError.errors[0]);
        }
        setBtnLoading(false);
      });
  }, [banner, updateBanner, addBanner, desktopPreviewUrl]);

  return (
    <Loader loading={!clients?.length}>
      <Modal open={open} onCancel={onClose} title="Client Banner" footer={null} width={1000} centered={true}>
        <Form
          form={form}
          initialValues={{
            ...banner,
            image: banner?.image || desktopPreviewUrl,
            mobile_image: banner?.mobile_image || mobilePreviewUrl,
            title: banner?.title,
            is_all: banner?.is_all ?? true
          }}
        >
          <Form.Item name={'title'} label="Title"
            labelCol={{ span: 24 }}
            required
            rules={[{ required: true, message: 'Please enter a title' }]}
          >
            <Input readOnly={isViewOnly} />
          </Form.Item>

          {!isViewOnly && <Row gutter={24}>
            <Col span={12}>
              <BannerUploader
                aspect={6}
                uploadProps={createUploadProps(false)}
                onModalCancel={() => {
                  setDesktopUploadReady(false);
                  setDesktopFileList([]);
                }}
                beforeCrop={(file) => {
                  setDesktopUploadReady(false);
                  return validateFormat(file);
                }}
                onModalOk={() => setDesktopUploadReady(true)}
                form={form}
              />
            </Col>
            <Col span={12}>
              <BannerUploader
                aspect={2}
                isMobile
                uploadProps={createUploadProps(true)}
                onModalCancel={() => {
                  setMobileUploadReady(false);
                  setMobileFileList([]);
                }}
                beforeCrop={(file) => {
                  setMobileUploadReady(false);
                  return validateFormat(file);
                }}
                onModalOk={() => setMobileUploadReady(true)}
                form={form}
              />
            </Col>
          </Row>}

          <ImagePreview
            imageUrl={desktopPreviewUrl}
            mobileImageUrl={mobilePreviewUrl}
            aspectRatio={6}
            isDefault={!desktopPreviewUrl}
          />

          <Form.Item
            label={<span>Redirect Link <Tooltip message="Banner would redirect to the link added here" /></span>}
            labelCol={{ span: 24 }}
            name="redirect_url"
            validateFirst
            className="label margin-top"
          >
            <Input readOnly={isViewOnly} />
          </Form.Item>

          <ReadOnlyWrapper isViewOnly={isViewOnly}>
            <Form.Item
              label={
                <span>
                  Clients <Tooltip message="Select the clients for which this banner is to be displayed" />
                </span>
              }
              labelCol={{ span: 24 }}
              name="is_all"
              validateFirst
              className="label margin-top"
            >
              {/* Wrap the Radio.Group to block interactions if isViewOnly */}
              <Radio.Group
                // onChange is still provided (or you can conditionally remove it)
                onChange={(e) => {
                  if (!isViewOnly) {
                    setIsAll(e.target.value);
                  }
                }}
              >
                <Radio value={true}>All Clients</Radio>
                <Radio value={false}>Specific Clients</Radio>
              </Radio.Group>
            </Form.Item>
          </ReadOnlyWrapper>

          {!isAll && (
            <ReadOnlyWrapper isViewOnly={isViewOnly}>
              <Form.Item
                label={
                  <span>
                    Clients <Tooltip message="Select the clients for which this banner is to be displayed" />
                  </span>
                }
                labelCol={{ span: 24 }}
                name="organisations"
                validateFirst
                className="label margin-top"
              >
                {/* Wrap the Select component similarly */}
                <Select
                  mode="multiple"
                  placeholder="Select Client"
                  showSearch
                  filterOption={(input, option) =>
                    (option?.children as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {clients?.map((item: Client) => (
                    <Select.Option key={item.uuid} value={item.uuid}>
                      {item.brand_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </ReadOnlyWrapper>
          )}

          <Row gutter={16} justify="start">
            {!isViewOnly && <Col>
              <Button type="primary" loading={btnLoading} onClick={submitHandler}>Save</Button>
            </Col>}
            <Col>
              <Button type="secondary" loading={btnLoading} onClick={onClose}>Close</Button>
            </Col>
          </Row>

          <Form.Item name="title" hidden>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </Loader>
  );
};

export default ClientBanner;
