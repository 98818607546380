import { Button, Image, Typography } from 'antd';
import { useState } from 'react';
import { MobileOutlined, TabletOutlined, DesktopOutlined } from '@ant-design/icons';
import './_image-preview.scss';

const { Title } = Typography;

interface ImagePreviewProps {
  imageUrl?: string;
  mobileImageUrl?: string;
  aspectRatio?: number;
  isDefault?: boolean;
}

type DeviceType = 'mobile' | 'tablet' | 'desktop';

const deviceWidths = {
  mobile: 320,
  tablet: 768,
  desktop: 1920
};

const deviceAspectRatios = {
  mobile: 2,
  tablet: 2,
  desktop: 6
};

const DEFAULT_PREVIEW_IMAGE = 'https://placehold.co/1920x320/36454F/FFF?text=Banner+Preview+(1920x320px)';
const DEFAULT_MOBILE_PREVIEW_IMAGE = 'https://placehold.co/320x160/36454F/FFF?text=Mobile+Banner+Preview+(1024x512px)';

export const ImagePreview = ({ imageUrl, mobileImageUrl, aspectRatio = 6, isDefault = false }: ImagePreviewProps) => {
  const [selectedDevice, setSelectedDevice] = useState<DeviceType>('desktop');

  const currentWidth = deviceWidths[selectedDevice];
  const currentAspectRatio = deviceAspectRatios[selectedDevice];
  const height = currentWidth / currentAspectRatio;

  const getPreviewImage = () => {
    if (isDefault && !imageUrl && !mobileImageUrl) {
      return selectedDevice === 'desktop' ? DEFAULT_PREVIEW_IMAGE : DEFAULT_MOBILE_PREVIEW_IMAGE;
    }
    if (selectedDevice === 'desktop') {
      return imageUrl || DEFAULT_PREVIEW_IMAGE;
    }
    return mobileImageUrl || DEFAULT_MOBILE_PREVIEW_IMAGE;
  };

  return (
    <div className="image-preview-container">
      <Title level={5} className="preview-title">
        {isDefault ? 'Sample Banner Preview' : 'Banner Preview'}
      </Title>
      <div className="device-selector">
        <Button
          type={selectedDevice === 'mobile' ? 'primary' : 'default'}
          icon={<MobileOutlined />}
          onClick={() => setSelectedDevice('mobile')}
        />
        <Button
          type={selectedDevice === 'tablet' ? 'primary' : 'default'}
          icon={<TabletOutlined />}
          onClick={() => setSelectedDevice('tablet')}
        />
        <Button
          type={selectedDevice === 'desktop' ? 'primary' : 'default'}
          icon={<DesktopOutlined />}
          onClick={() => setSelectedDevice('desktop')}
        />
      </div>
      <div className="preview-frame" style={{ maxWidth: currentWidth }}>
        <div className="aspect-ratio-box" style={{ paddingTop: `${(1/currentAspectRatio) * 100}%` }}>
          <div className="image-container">
            <Image
              src={getPreviewImage()}
              preview={false}
              className="preview-image"
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </div>
      <div className="device-info">
        Current view: {selectedDevice}
      </div>
    </div>
  );
};