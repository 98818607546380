interface ReadOnlyWrapperProps {
  isViewOnly: boolean;
  children: React.ReactNode;
}

export const ReadOnlyWrapper: React.FC<ReadOnlyWrapperProps> = ({ isViewOnly, children }) => (
  <div style={{ position: 'relative' }}>
    {children}
    {isViewOnly && (
      <div
        // This invisible overlay covers the children and intercepts all mouse events.
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 10,
          // backgroundColor: 'transparent' ensures it doesn't affect appearance.
          backgroundColor: 'transparent'
        }}
      />
    )}
  </div>
);
